require('./block/slider');
require('./block/burger');
require('./block/sideBar');
require('./block/wow');
require('./block/headerFixed');
require('./block/up');
require('./block/rellax');
require('./block/mask');


$(() => {
    const headerH = document.querySelector('.header, .footer').getBoundingClientRect().height;

    document.querySelectorAll('header a[href^="#"], footer a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {

            e.preventDefault();

            let currentHref    = this.getAttribute('href'),
                currentAnchor  = document.getElementById(currentHref.substring(1)),
                currentAnchorT = currentAnchor.getBoundingClientRect().top;

            window.scrollTo({
                top: currentAnchorT - headerH + window.scrollY,
                behavior: 'smooth'
            });

        });
    });

    if (window.innerWidth > 768) {
        let bg = document.querySelectorAll('.triangle__parallax');
        for (let i = 0; i < bg.length; i++){
            window.addEventListener('mousemove', function(e) {
                let x = e.clientX / window.innerWidth;
                let y = e.clientY / window.innerHeight;
                bg[i].style.transform = 'translate(-' + x * 25 + 'px, -' + y * 25 + 'px)';
            });
        }
    }

    function detectBrowser() {
        if (navigator.userAgent.includes("Chrome")) {
            return "chrome"
        }
        if (navigator.userAgent.includes("Firefox")) {
            return "firefox"
        }
        if (navigator.userAgent.includes("Safari")) {
            return "safari"
        }
    }
    document.body.className = detectBrowser()

});
