(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$(() => {
    let toggle = document.querySelector('.burger-toggle');

    toggle.addEventListener('click', function(e) {
        this.classList.toggle('opened');
    });

});
},{}],2:[function(require,module,exports){
$(() => {
    $(window).scroll(function(){
        if ($(this).scrollTop() > 700) {
            $('.header-fix').addClass('fixed');
        } else {
            $('.header-fix').removeClass('fixed');
        }
    });
});

},{}],3:[function(require,module,exports){
$(() => {
    let $input = $('.email-1, .email-2');
    let cursor = $input[0].selectionStart;
    let prev = $input.val();

    $input.inputmask({
        mask: "*{1,50}[.*{1,50}][.*{1,50}]@*{1,50}.*{1,20}[.*{1,20}][.*{1,20}]",
        greedy: false,
        //clearIncomplete: true,// если нужно чистить поле, когда не закончили вводить email
        showMaskOnHover: false,
        definitions: {
            '*': {
                validator: "[^_@.]"
            }
        }
    }).on('input paste', function() {
        if (this.value && /[^_a-zA-Z0-9@\-.]/i.test(this.value)) {
            this.value = prev;
            this.setSelectionRange(cursor, cursor);
            $input.trigger('input');
        } else {
            cursor = this.selectionStart;
            prev = this.value;
        }

        actionAfterValidate(this);
    });

    $input.blur(function() {
        actionAfterValidate(this);
    })

    function emailValidator(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    function actionAfterValidate(input) {
        const error = $(input).parents('.email-wrapper').find('.error')
        const warning = $(input).parents('.email-wrapper').find('.warning')
        console.log(input.value)
        if(input.value === '') {
            warning.removeClass('not-visible')
            error.addClass('not-visible')
            $(input).removeClass('input-error')
        } else {
            warning.addClass('not-visible')

            if(input.value !== "" && emailValidator(input.value)) {
                error.addClass('not-visible')
                $(input).removeClass('input-error')
            } else {
                error.removeClass('not-visible')
                $(input).addClass('input-error')
            }
        }
    }


    let $inputPhone = $('.phone-wrapper > input');
    let cursorPhone = $inputPhone[0].selectionStart;
    let prevPhone = $inputPhone.val();

    $inputPhone.inputmask({
        mask: "+7 (###) ###-####",
    }).on('input paste', function() {
        const error = $(this).parents('.phone-wrapper').find('.error')
        const warning = $(this).parents('.phone-wrapper').find('.warning')
        const input = $(this).parents('.phone-wrapper').find('input')
        if(this.value === '') {
            warning.removeClass('not-visible')
            error.addClass('not-visible')
            input.removeClass('input-error')
        } else {
            warning.addClass('not-visible')

            if (this.value && /^[\+][7]?[-\s\.]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/i.test(this.value)) {
                error.addClass('not-visible')
                input.removeClass('input-error')
            } else {
                error.removeClass('not-visible')
                input.addClass('input-error')
            }
        }
    });

    $inputPhone.blur(function() {
        const error = $(this).parents('.phone-wrapper').find('.error')
        const warning = $(this).parents('.phone-wrapper').find('.warning')
        const input = $(this).parents('.phone-wrapper').find('input')
        if(this.value === '') {
            warning.removeClass('not-visible')
            error.addClass('not-visible')
            input.removeClass('input-error')
        } else {
            warning.addClass('not-visible')
        }

        if (this.value !== '' && this.value.length > 0 && !(/^[\+][7]?[-\s\.]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/i.test(this.value))) {
            error.removeClass('not-visible')
            input.addClass('input-error')
        }
    })
});

},{}],4:[function(require,module,exports){
$(() => {
    let rellax = new Rellax('.rellax');
});
},{}],5:[function(require,module,exports){
$(() => {
	let obj = $('.sideBar');
	let content = $('.sideBar-content');
	let body = $('body');

	function toggle() {
		obj.toggleClass('sideBar_visible');
		body.toggleClass('noScroll');
	}

	$('.js-sideBarCtrl').click(toggle);
	content.click((e) => {
		e.stopPropagation();
	});
	obj.click(toggle);

	$('.sideBar .menu-li a').click(e => {
		e.stopPropagation();
		toggle();
		$('.js-sideBarCtrl').removeClass('opened');
	})
});


},{}],6:[function(require,module,exports){
$(() => {
    $('.slider-carousel').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false
    });

    // previous
    $('.button-previous').on( 'click', function() {
        $('.slider-carousel').flickity('previous');
    });
    // next
    $('.button-next').on( 'click', function() {
        $('.slider-carousel').flickity('next');
    });

    $('.slider-activity').flickity({
        // options
        cellAlign: 'left',
        groupCells: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.15,
        pageDots: true,
        wrapAround: true,
        draggable: false
    });

    // previous
    $('.button-previous-2').on( 'click', function() {
        $('.slider-activity').flickity('previous');
    });
    // next
    $('.button-next-2').on( 'click', function() {
        $('.slider-activity').flickity('next');
    });

    $('.slider-activity-mobileBlock').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.15,
        pageDots: true,
        wrapAround: true,
        draggable: false
    });

    // previous
    $('.button-previous-3').on( 'click', function() {
        $('.slider-activity-mobileBlock').flickity('previous');
    });
    // next
    $('.button-next-3').on( 'click', function() {
        $('.slider-activity-mobileBlock').flickity('next');
    });

    const sliderProjects = $('.slider-projects')
    sliderProjects.on( 'ready.flickity', function() {
        const sliderProjectsView = $('.slider-projects .flickity-viewport')
        const counterHeight = $('.slider-projects .slider-projects-item:nth-child(1) .slider-count').height()
        const bottomPadding = 10 // Доп отступ снизу от счетчика
        sliderProjectsView.css({'height': (sliderProjectsView.height() + counterHeight + bottomPadding)+'px'})
    });
    sliderProjects.flickity({
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    // previous
    $('.button-previous-4').on( 'click', function() {
        $('.slider-projects').flickity('previous');
    });
    // next
    $('.button-next-4').on( 'click', function() {
        $('.slider-projects').flickity('next');
    });

    const landmarksCarousel = $('.landmarks-carousel').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        groupCells: 1,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: false,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    landmarksCarousel.on( 'change.flickity', function( event, index ) {
        const fixedIndex = index + 1
        $('.landmarks-slider .counter-first').text(fixedIndex)
        $('.landmarks-list p').removeClass('active_slide')
        $(`.landmarks-list p:nth-child(${fixedIndex})`).addClass('active_slide')
    });

    // previous
    $('.button-previous-5').on( 'click', function() {
        $('.landmarks-carousel').flickity('previous');
    });
    // next
    $('.button-next-5').on( 'click', function() {
        $('.landmarks-carousel').flickity('next');
    });

    $('.partners-slider').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        groupCells: 1,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    // previous
    $('.button-previous-6').on( 'click', function() {
        $('.partners-slider').flickity('previous');
    });
    // next
    $('.button-next-6').on( 'click', function() {
        $('.partners-slider').flickity('next');
    });

    $('.vacancies-slider').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        groupCells: 1,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    // previous
    $('.button-previous-7').on( 'click', function() {
        $('.vacancies-slider').flickity('previous');
    });
    // next
    $('.button-next-7').on( 'click', function() {
        $('.vacancies-slider').flickity('next');
    });


});


},{}],7:[function(require,module,exports){
$(() => {

    let btn = $('.up');

    btn.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '1500');
    });
});
},{}],8:[function(require,module,exports){
$(() => {
    new WOW().init();
});


},{}],9:[function(require,module,exports){
require('./block/slider');
require('./block/burger');
require('./block/sideBar');
require('./block/wow');
require('./block/headerFixed');
require('./block/up');
require('./block/rellax');
require('./block/mask');


$(() => {
    const headerH = document.querySelector('.header, .footer').getBoundingClientRect().height;

    document.querySelectorAll('header a[href^="#"], footer a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {

            e.preventDefault();

            let currentHref    = this.getAttribute('href'),
                currentAnchor  = document.getElementById(currentHref.substring(1)),
                currentAnchorT = currentAnchor.getBoundingClientRect().top;

            window.scrollTo({
                top: currentAnchorT - headerH + window.scrollY,
                behavior: 'smooth'
            });

        });
    });

    if (window.innerWidth > 768) {
        let bg = document.querySelectorAll('.triangle__parallax');
        for (let i = 0; i < bg.length; i++){
            window.addEventListener('mousemove', function(e) {
                let x = e.clientX / window.innerWidth;
                let y = e.clientY / window.innerHeight;
                bg[i].style.transform = 'translate(-' + x * 25 + 'px, -' + y * 25 + 'px)';
            });
        }
    }

    function detectBrowser() {
        if (navigator.userAgent.includes("Chrome")) {
            return "chrome"
        }
        if (navigator.userAgent.includes("Firefox")) {
            return "firefox"
        }
        if (navigator.userAgent.includes("Safari")) {
            return "safari"
        }
    }
    document.body.className = detectBrowser()

});

},{"./block/burger":1,"./block/headerFixed":2,"./block/mask":3,"./block/rellax":4,"./block/sideBar":5,"./block/slider":6,"./block/up":7,"./block/wow":8}]},{},[9])

