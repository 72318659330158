$(() => {
	let obj = $('.sideBar');
	let content = $('.sideBar-content');
	let body = $('body');

	function toggle() {
		obj.toggleClass('sideBar_visible');
		body.toggleClass('noScroll');
	}

	$('.js-sideBarCtrl').click(toggle);
	content.click((e) => {
		e.stopPropagation();
	});
	obj.click(toggle);

	$('.sideBar .menu-li a').click(e => {
		e.stopPropagation();
		toggle();
		$('.js-sideBarCtrl').removeClass('opened');
	})
});

