$(() => {
    $('.slider-carousel').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false
    });

    // previous
    $('.button-previous').on( 'click', function() {
        $('.slider-carousel').flickity('previous');
    });
    // next
    $('.button-next').on( 'click', function() {
        $('.slider-carousel').flickity('next');
    });

    $('.slider-activity').flickity({
        // options
        cellAlign: 'left',
        groupCells: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.15,
        pageDots: true,
        wrapAround: true,
        draggable: false
    });

    // previous
    $('.button-previous-2').on( 'click', function() {
        $('.slider-activity').flickity('previous');
    });
    // next
    $('.button-next-2').on( 'click', function() {
        $('.slider-activity').flickity('next');
    });

    $('.slider-activity-mobileBlock').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.15,
        pageDots: true,
        wrapAround: true,
        draggable: false
    });

    // previous
    $('.button-previous-3').on( 'click', function() {
        $('.slider-activity-mobileBlock').flickity('previous');
    });
    // next
    $('.button-next-3').on( 'click', function() {
        $('.slider-activity-mobileBlock').flickity('next');
    });

    const sliderProjects = $('.slider-projects')
    sliderProjects.on( 'ready.flickity', function() {
        const sliderProjectsView = $('.slider-projects .flickity-viewport')
        const counterHeight = $('.slider-projects .slider-projects-item:nth-child(1) .slider-count').height()
        const bottomPadding = 10 // Доп отступ снизу от счетчика
        sliderProjectsView.css({'height': (sliderProjectsView.height() + counterHeight + bottomPadding)+'px'})
    });
    sliderProjects.flickity({
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    // previous
    $('.button-previous-4').on( 'click', function() {
        $('.slider-projects').flickity('previous');
    });
    // next
    $('.button-next-4').on( 'click', function() {
        $('.slider-projects').flickity('next');
    });

    const landmarksCarousel = $('.landmarks-carousel').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        groupCells: 1,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: false,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    landmarksCarousel.on( 'change.flickity', function( event, index ) {
        const fixedIndex = index + 1
        $('.landmarks-slider .counter-first').text(fixedIndex)
        $('.landmarks-list p').removeClass('active_slide')
        $(`.landmarks-list p:nth-child(${fixedIndex})`).addClass('active_slide')
    });

    // previous
    $('.button-previous-5').on( 'click', function() {
        $('.landmarks-carousel').flickity('previous');
    });
    // next
    $('.button-next-5').on( 'click', function() {
        $('.landmarks-carousel').flickity('next');
    });

    $('.partners-slider').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        groupCells: 1,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    // previous
    $('.button-previous-6').on( 'click', function() {
        $('.partners-slider').flickity('previous');
    });
    // next
    $('.button-next-6').on( 'click', function() {
        $('.partners-slider').flickity('next');
    });

    $('.vacancies-slider').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        groupCells: 1,
        prevNextButtons: false,
        selectedAttraction: 0.01,
        friction: 0.18,
        pageDots: true,
        wrapAround: true,
        draggable: false,
        imagesLoaded: true
    });

    // previous
    $('.button-previous-7').on( 'click', function() {
        $('.vacancies-slider').flickity('previous');
    });
    // next
    $('.button-next-7').on( 'click', function() {
        $('.vacancies-slider').flickity('next');
    });


});

